import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import { RetrieveRequest, UpdateRequest } from '@/models/paynl';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { bloqifyFunctions } from '@/boot/firebase';

const SET_PAYNL_DATA = 'SET_PAYNL_DATA';

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_PAYNL_DATA](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    /**
     * Action that which will get the requested decrypted payNL api keys from Firestore.
     */
    async getPayNLKeys({ commit }, retrieveRequestOptions: RetrieveRequest): Promise<void> {
      commit(SET_PAYNL_DATA, { status: DataContainerStatus.Processing, operation: `getPayNLKeys-${retrieveRequestOptions.idempotentKey}` });

      const [requestError, requestData] = await to(bloqifyFunctions.httpsCallable('payNLKMS')(retrieveRequestOptions));
      if (requestError) {
        commit(
          SET_PAYNL_DATA,
          { status: DataContainerStatus.Error, operation: `getPayNLKeys-${retrieveRequestOptions.idempotentKey}`, payload: requestError },
        );
        return;
      }

      commit(
        SET_PAYNL_DATA,
        { status: DataContainerStatus.Success, operation: `getPayNLKeys-${retrieveRequestOptions.idempotentKey}`, payload: requestData!.data.data },
      );
    },
    /**
     * Action that will set the payNL api keys encrypted in Firestore.
     */
    async setPayNLKeys({ commit }, updateRequestOptions: UpdateRequest): Promise<void> {
      commit(SET_PAYNL_DATA, { status: DataContainerStatus.Processing, operation: `setPayNLKeys-${updateRequestOptions.idempotentKey}` });

      const [requestError, requestData] = await to(bloqifyFunctions.httpsCallable('payNLKMS')(updateRequestOptions));
      if (requestError) {
        commit(
          SET_PAYNL_DATA,
          { status: DataContainerStatus.Error, operation: `setPayNLKeys-${updateRequestOptions.idempotentKey}`, payload: requestError },
        );
        return;
      }

      commit(
        SET_PAYNL_DATA,
        { status: DataContainerStatus.Success, operation: `setPayNLKeys-${updateRequestOptions.idempotentKey}`, payload: requestData!.data.data },
      );
    },
  },
  getters: {},
};
