import Vue from 'vue';
import { DataContainerStatus } from '@/models/Common';

// Separate interfaces for each status
export interface InitialVertebra {
  status: DataContainerStatus.Initial;
  payload: null;
  error: null;
  operation: null;
}

export interface ProcessingVertebra {
  status: DataContainerStatus.Processing;
  payload: null;
  error: null;
  operation: string;
}

export interface ErrorVertebra {
  status: DataContainerStatus.Error;
  payload: null;
  error: Error;
  operation: string;
}

export interface SuccessVertebra<T = any> {
  status: DataContainerStatus.Success;
  payload: T;
  error: null;
  operation: string;
}

// Discriminated union type
export type Vertebra<T = any> =
  | InitialVertebra
  | ProcessingVertebra
  | ErrorVertebra
  | SuccessVertebra<T>

export const generateState = <T = any>(): Vertebra<T> => ({
  status: DataContainerStatus.Initial,
  payload: null,
  error: null,
  operation: null,
});

const mutationPending = (state: Vertebra, operation: string): void => {
  state.status = DataContainerStatus.Processing;
  state.payload = null;
  state.error = null;
  state.operation = operation;
};

const mutationSuccess = (state: Vertebra, operation: string, payload?: any): void => {
  state.status = DataContainerStatus.Success;
  state.payload = payload || null;
  state.error = null;
  state.operation = operation;
};

const mutationFail = (state: Vertebra, operation: string, payload: Error): void => {
  state.status = DataContainerStatus.Error;
  state.payload = null;
  state.error = payload;
  state.operation = operation;
};

export const mutateState = (state: Vertebra, vertebraStatus: DataContainerStatus, operation: string, payload?: any): void => {
  // Processing && avoiding double setting of processing state
  if (vertebraStatus === DataContainerStatus.Processing) {
    return state.status !== DataContainerStatus.Processing ? mutationPending(state, operation) : undefined;
  }

  // Success or Error
  return vertebraStatus === DataContainerStatus.Error
    ? mutationFail(state, operation, payload)
    : mutationSuccess(state, operation, payload);
};
