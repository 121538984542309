import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { Asset } from '@/models/assets/Asset';
import { Dividend } from '@/models/assets/Dividends';
import { Earning, Investment } from '@/models/investments/Investment';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import BigNumber from 'bignumber.js';

const SET_DIVIDENDS = 'SET_DIVIDENDS';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_DIVIDENDS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addDividend(
      { commit },
      { amount, assetId, paymentDateTime }: { amount: number, assetId: string, paymentDateTime: Date },
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'addDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const newDividendRef = assetRef.collection('dividends').doc();

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'This asset does not exist');
        }

        // Query investments
        const [investmentsQueryError, investmentsQuery] = await to(bloqifyFirestore.collection('investments').where('asset', '==', assetRef).get());

        if (investmentsQueryError || !investmentsQuery || investmentsQuery.empty) {
          throw Error(investmentsQueryError?.message || 'There are no investments for this asset');
        }

        // The total value needed to determine how many euros each investor gets
        const totalValueEuro = assetSnapshot.get('totalValueEuro') as Asset['totalValueEuro'];

        investmentsQuery.forEach(async (investmentSnapshot): Promise<void> => {
          const investorRef = investmentSnapshot.get('investor') as Investment['investor'];

          // Calculate earning amount and round it with 2 digit precision
          const paidEuroTotal = (investmentSnapshot.get('paidEuroTotal') as Investment['paidEuroTotal']) || 0;
          const earningAmount = new BigNumber(paidEuroTotal)
            .dividedBy(totalValueEuro)
            .multipliedBy(amount)
            .dp(2)
            .toNumber();

          transaction.update(investmentSnapshot.ref, {
            totalEarnings: firebase.firestore.FieldValue.increment(earningAmount),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          transaction.set(investmentSnapshot.ref.collection('earnings').doc(), {
            amount: earningAmount,
            paymentDateTime,
            dividend: newDividendRef,
            investor: investorRef,
            investment: investmentSnapshot.ref,
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          transaction.update(investmentSnapshot.ref, {
            totalEarningAmount: firebase.firestore.FieldValue.increment(earningAmount),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amount),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        transaction.set(newDividendRef, {
          amount,
          asset: assetRef,
          paymentDateTime: firebase.firestore.Timestamp.fromDate(paymentDateTime),
          createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addDividend' });
      }

      return commit(SET_DIVIDENDS, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'addDividend' });
    },
    async updateDividend(
      { commit },
      {
        amount, assetId, dividendId, paymentDateTime,
      }:
      {
        amount: number, assetId: string, dividendId: string, paymentDateTime: Date,
      },
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'updateDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const dividendRef = assetRef.collection('dividends').doc(dividendId);

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read dividend
        const [dividendSnapshotError, dividendSnapshot] = await to(transaction.get(dividendRef));

        if (dividendSnapshotError || !dividendSnapshot || !dividendSnapshot.exists) {
          throw Error(dividendSnapshotError?.message || 'There was an error fetching the dividend');
        }

        // Query earnings
        const [earningsQueryError, earningsQuery] = await to(bloqifyFirestore.collectionGroup('earnings').where('dividend', '==', dividendRef).get());

        if (earningsQueryError || !earningsQuery || earningsQuery.empty) {
          throw Error(earningsQueryError?.message || 'There was an error fetching the earnigns');
        }

        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'There was an error fetching the asset');
        }

        // The total value needed to determine how many euros each investor gets
        const totalValueEuro = assetSnapshot.get('totalValueEuro') as Asset['totalValueEuro'];

        // The difference between the two amounts (positive if the amount increased, negative otherwise)
        const amountDifference = amount - (dividendSnapshot.get('amount') as Dividend['amount']);

        // The updates array will contain all references with the required updates
        const updates: [firebase.firestore.DocumentReference, Record<string, any>][] = [];

        earningsQuery.forEach(async (earningSnapshot): Promise<void> => {
          const investmentRef = (earningSnapshot.get('investment') as Earning['investment']) as firebase.firestore.DocumentReference;
          const [investmentSnapshotError, investmentSnapshot] = await to(transaction.get(investmentRef));

          if (investmentSnapshotError || !investmentSnapshot || !investmentSnapshot.exists) {
            throw Error(investmentSnapshotError?.message || `There was an error fetching the investment for earning ${earningSnapshot.id}`);
          }

          const paidEuroTotal = (investmentSnapshot.get('paidEuroTotal') as Investment['paidEuroTotal']) || 0;
          const earningAmountDiff = (paidEuroTotal / totalValueEuro) * amountDifference;
          const roundedEarningAmountDiff = Math.round((earningAmountDiff + Number.EPSILON) * 100) / 100;

          updates.push([
            earningSnapshot.ref,
            {
              amount: roundedEarningAmountDiff,
              paymentDateTime: firebase.firestore.Timestamp.fromDate(paymentDateTime),
              updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
          ]);

          updates.push([
            investmentRef,
            {
              totalEarnings: firebase.firestore.FieldValue.increment(roundedEarningAmountDiff),
              updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            },
          ]);

          // transaction.update(earningSnapshot.ref, {
          //   amount: roundedEarningAmountDiff,
          //   period: firebase.firestore.Timestamp.fromDate(period),
          //   updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          // });

          // transaction.update(investmentRef, {
          //   totalEarnings: firebase.firestore.FieldValue.increment(roundedEarningAmountDiff),
          //   updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          // });
        });

        transaction.update(assetRef, {
          totalDividendAmount: firebase.firestore.FieldValue.increment(amountDifference),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        transaction.update(dividendRef, {
          amount,
          paymentDateTime: firebase.firestore.Timestamp.fromDate(paymentDateTime),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        updates.forEach(([ref, obj]): void => { transaction.update(ref, obj); });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateDividend' });
      }

      return commit(SET_DIVIDENDS, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updateDividend' });
    },
  },
};
