import { firebase, bloqifyFirestore } from '@/boot/firebase';

export class BatchInstance {
  private _firestore: firebase.firestore.Firestore;
  private _batch: firebase.firestore.WriteBatch;
  private _size = 0;
  private _batches: firebase.firestore.WriteBatch[];

  /**
   * initiate batch
   */
  public constructor(fs: firebase.firestore.Firestore) {
    this._firestore = fs;
    this._batch = this._firestore.batch();
    this._batches = [];
  }

  /**
   * Set or overwrite data to new or existing document
   * @param doc Docuement to be set
   * @param data Data to be applied
   */
  set(doc: firebase.firestore.DocumentReference, data: any): void {
    this._batch.set(doc, data);
    this._size++;
    this.addBatchIfFull();
  }

  /**
   * Delete a document
   * @param doc document to be deleted
   */
  delete(doc: firebase.firestore.DocumentReference): void {
    this._batch.delete(doc);
    this._size++;
    this.addBatchIfFull();
  }

  /**
   * Apply an update to a document. The document MUST exist or the entire batch fails.
   * @param doc what doc to update
   * @param data the data that needs updating
   */
  update(doc: firebase.firestore.DocumentReference, data: any): void {
    this._batch.update(doc, data);
    this._size++;
    this.addBatchIfFull();
  }

  private addBatchIfFull(): void {
    if (this._size < 500) return;
    this._batches.push(this._batch);
    this.resetBatch();
  }

  async commit(commitInOrder: boolean = false): Promise<void> {
    // if any docs left in current batch, push to batch list
    if (this._size > 0) this._batches.push(this._batch);

    // if batch list has any batches
    if (this._batches.length > 0) {
      console.log(`Committing ${this._batches.length * 500 + this._size} changes`);
      // if they have to be commited in order:
      if (commitInOrder) {
        // eslint-disable-next-line no-restricted-syntax
        for (const b of this._batches) {
          // eslint-disable-next-line no-useless-catch
          try {
            // eslint-disable-next-line no-await-in-loop
            await b.commit();
          } catch (e) {
            throw e;
          }
        }
        // else commit them to a new list of promises
      } else {
        // eslint-disable-next-line no-useless-catch
        try {
          await Promise.all(this._batches.map((b): any => b.commit()));
        } catch (e) {
          throw e;
        }
      }
    }

    // resolve promises;
    // eslint-disable-next-line no-useless-catch
    try {
      await Promise.all(this._batches);
    } catch (e) {
      throw e;
    }

    this._batches = [];
    this.resetBatch();
  }

  private resetBatch(): void {
    this._size = 0;
    this._batch = this._firestore.batch();
  }
}

/**
 * Handles limit of 500 documents write per transaction using multiple batches.
 * @param transaction
 * @param operations map which has the payment ref and transaction op as keys plus the object to be written as value
 * @param docsWritten number of documents written previously by the transaction
 * @param docsToWrite number of documents to be written after these operations
 */
export const multipleTransactionAction = async (
  transaction: firebase.firestore.Transaction,
  operations: Map<[firebase.firestore.DocumentReference, 'update' | 'set' | 'delete'], { [key: string]: any } | null>,
  docsWritten: number,
  docsToWrite: number,
): Promise<void> => {
  const reachesLimit = docsWritten + docsToWrite + operations.size > 500;
  const adaptedTransaction = !reachesLimit ? transaction : new BatchInstance(bloqifyFirestore);

  [...operations.entries()].forEach(([[ref, opKey], values]): void => {
    if (values) {
      // @ts-ignore
      adaptedTransaction[opKey](ref, values);
      return;
    }
    // @ts-ignore
    adaptedTransaction[opKey](ref);
  });

  if (reachesLimit) {
    return (adaptedTransaction as BatchInstance).commit();
  }

  return undefined;
};
