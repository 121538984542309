/* eslint-disable typescript/no-use-before-define */
import firebase from 'firebase/app';
import { Asset } from '../assets/Asset';
import { Dividend } from '../assets/Dividends';
import { Investor } from '../users/User';

/**
 * Defining all the statuses a Payment can have.
 */
export const enum PaymentStatus {
  Open = 'open',
  Cancel = 'cancel',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
  Refund = 'refund',
  Declined = 'declined',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Mollie = 'Mollie',
  PayNL = 'PayNL',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

/**
 * Defining all the types a Investment Subscription can have.
 */
 export const enum InvestmentSubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  OnHold = 'on-hold',
  PendingCancel = 'pending-cancel'
}

/**
 * The object to keep track of Investment Subscriptions.
 */
export interface InvestmentSubscriptions {
  [paymentId: string]: InvestmentSubscriptionStatus;
}

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference;
  asset: firebase.firestore.DocumentReference;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  receivedSharesTotal?: number;
  receivedEuroTotal?: number;
  giftEuroTotal?: number;
  giftSharesTotal?: number;
  totalEuroEarnings?: number;

  // Payment counters
  paidPayments?: number;
  openPayments?: number;
  deletedPayments?: number;
  subscriptions?: InvestmentSubscriptions;
}

// Earning subcollection for storing monthly rental payouts
export interface Earning {
  amount: number,
  paymentDateTime: firebase.firestore.Timestamp,
  dividend: firebase.firestore.DocumentReference | Dividend;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

const enum CustomOrderStatus {
  Completed = 'wc-completed',
  Pending = 'wc-pending', // Subscription
  OnHold = 'wc-on-hold', // Subscription
  Refunded = 'wc-refunded', // Completed but refunded
  // Delete with status:
  Trash = 'trash', // No serials and can’t find the order as it is in trash
  Failed = 'wc-failed', // Other order is completed
  Cancelled = 'wc-cancelled', // Other order is completed
  AutoDraft = 'wc-auto-draft', // Is a media post
  Draft = 'draft' // No client or payment
}

export interface GiftCode {
  orderPayment: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  orderInvestment: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  code: string;
  treeAmount: number;
  amount: number;
  deleted: boolean;
  currency: string;
  paymentDate: firebase.firestore.Timestamp;
  expirationDate: firebase.firestore.Timestamp;
  orderInvestor: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  claimInvestor?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  claimPayment?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  claimInvestment?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  createdDateTime?: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

export interface Tree {
  id?: string;
  asset: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | Asset;
  investor: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | Investor;
  investment: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | Investment;
  // eslint-disable-next-line no-use-before-define
  payment: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | Payment;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
  deleted?: boolean;
}

export enum PaymentType {
  OneOff = 'one-off',
  Subscription = 'subscription',
  StartSubscription = 'start-subscription',
  Gift = 'gift',
  GiftPurchase = 'gift-purchase',
  GiftRedeem = 'gift-redeem',
  ModifiedSubscription = 'modified-subscription',
}

export interface Referral {
  id?: string,
  investor: firebase.firestore.DocumentReference | Investor,
  code: string, // we have to be sure this is unique
  currency: string,
  amount: number,
  createdDateTime: firebase.firestore.Timestamp,
  updatedDateTime: firebase.firestore.Timestamp,
  totalRedeemed: number | firebase.firestore.FieldValue,
  // Thinking on future implementations
  expirationDate?: firebase.firestore.Timestamp,
  limitPerPayment?: number,
}

export interface Coupon {
  investor: firebase.firestore.DocumentReference,
  payment: firebase.firestore.DocumentReference,
  code: string, // we have to be sure this is unique
  currency: string,
  amount: number,
  createdDateTime: firebase.firestore.Timestamp,
  updatedDateTime: firebase.firestore.Timestamp,
  // Thinking on future implementations
  expirationDate?: firebase.firestore.Timestamp,
  limitPerPayment?: number,
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  customStatus?: CustomOrderStatus,
  provider: PaymentProvider;
  orderId?: string;
  subscriptionId?: string;
  mandateId?: string;
  processDate?: firebase.firestore.Timestamp;
  providerData: {
    id: string,
    amount: {
      currency: string,
      value: string | number,
    },
    status: PaymentStatus,
    metadata: {
      uid?: string,
      euroAmount: number,
      sharesAmount: number,
      investmentId?: string,
      assetId?: string,
      paymentId?: string,
      selectedDividendsFormatYear?: [string, number];
    },
    [key: string]: any,
  };
  type?: PaymentType;
  deleted: boolean;
  trees?: firebase.firestore.DocumentReference[] | Tree[];
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  endDateTime?: firebase.firestore.Timestamp;
  gift?: firebase.firestore.DocumentReference;
  referral?: firebase.firestore.DocumentReference | Referral;
  coupon?: firebase.firestore.DocumentReference | Coupon;
}
